/* Subscribe Checkbox styles for visually.io A/B testing purposes */
.custom-checkbox:checked + .custom-checkbox-checkmark::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0.25rem;
  transform: rotate(45deg);
  border: solid var(--white);
  border-width: 0 2px 2px 0;
  width: 6px;
  height: 10px;
}
